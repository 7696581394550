import { downloadCsv } from "@/lib/csv";
import {
  buildOrderingQueryParamsFromPagination,
  Pagination,
} from "@/lib/pagination";
import { appendQueryParams } from "@/lib/urlBuilder";

import { buildMessageDeliveryQueryParamsFromFilters } from "./filters";
import { MessageDeliveryFilters } from "./typedefs";

export async function downloadMessageDeliveryListAsCsv(
  productLine: number,
  filters: MessageDeliveryFilters,
  pagination: Pagination
): Promise<void> {
  const queryParams = {
    ...buildMessageDeliveryQueryParamsFromFilters(filters),
    ...buildOrderingQueryParamsFromPagination(pagination),
  };
  const path = `/api/pushnotifications/product-lines/${productLine}/message-deliveries-csv`;
  const url = appendQueryParams(path, queryParams);

  downloadCsv(url);
}
