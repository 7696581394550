import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row q-gutter-sm" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_time_input = _resolveComponent("time-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_time_input, {
        disable: _ctx.disable,
        "data-test": "timerangeinput.input.starttime",
        "model-value": _ctx.startTime,
        label: _ctx.startTimeLabel,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:startTime', $event)))
      }, null, 8, ["disable", "model-value", "label"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_time_input, {
        disable: _ctx.disable,
        "data-test": "timerangeinput.input.endtime",
        "model-value": _ctx.endTime,
        label: _ctx.endTimeLabel,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:endTime', $event)))
      }, null, 8, ["disable", "model-value", "label"])
    ])
  ]))
}