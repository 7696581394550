import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { computed, Ref, ref, watch } from "vue";
import { LocationQuery, useRouter } from "vue-router";

import {
  buildMessageDeliveryFiltersFromQueryParams,
  fetchMessageDeliveryUsers,
  MessageDelivery,
} from "@/api/pushnotifications/messagedelivery";
import { statusOptions } from "@/api/pushnotifications/messagedelivery";
import { downloadMessageDeliveryListAsCsv } from "@/api/pushnotifications/messagedelivery/listDownloadCsv";
import { DataState } from "@/composables/typedefs";
import { domUtils } from "@/lib/domUtils";
import { buildPaginationFromQueryParams, Pagination } from "@/lib/pagination";

import { useMessageDeliveryList } from "./useMessageDeliveryList";
import { usePushMessagePermissions } from "./usePushMessagePermissions";

const MESSAGE_ROW_SEPARATOR = "\t";
const DEFAULT_PUSH_MESSAGES_PAGINATION: Partial<Pagination> = {
  sortBy: "message_id",
  descending: true,
};

interface UsePushMessagesOptions {
  productLine: Ref<number>;
  product: Ref<number>;
  queryParams: Ref<LocationQuery>;
}

export function usePushMessages({
  product,
  productLine,
  queryParams,
}: UsePushMessagesOptions) {
  const router = useRouter();

  const initialPagination = buildPaginationFromQueryParams(
    queryParams.value,
    DEFAULT_PUSH_MESSAGES_PAGINATION
  );

  const {
    messageDeliveries,
    pagination,
    onPaginationChange,
    filters,
    onFiltersChange,
    state,
  } = useMessageDeliveryList({
    productLine,
    initialFilters: buildMessageDeliveryFiltersFromQueryParams(
      queryParams.value
    ),
    initialPagination,
  });

  const $q = useQuasar();

  const isLoading = computed(() => state.value === DataState.LOADING);
  const isError = computed(() => state.value === DataState.ERROR);

  const onAddNewPushMessage = () =>
    router.push({
      // TODO(PNS-1467): Use route name when implemented.
      path: `/pushnotifications/message/${product.value}/${productLine.value}`,
    });

  const onOpenExistingPushMessage = (id: number) => {
    // TODO(PNS-1467): Use route name when implemented.
    router.push({
      path: `/pushnotifications/message/${product.value}/${productLine.value}/${id}`,
    });
  };

  const onOpenExistingPushMessageInNewTab = (id: number) => {
    // TODO(PNS-1467): Use route name when implemented.
    domUtils
      .getWindow()
      .open(
        `/pushnotifications/message/${product.value}/${productLine.value}/${id}`,
        "_blank"
      );
  };

  const onClipboardCopyMessageRow = (row: MessageDelivery) => {
    const contentArray = [
      row.message.id,
      row.status,
      row.message.name,
      row.deliveryTime,
      row.message.createdBy,
      row.messagesSent,
      row.estimatedUsers,
    ];

    const content = contentArray.join(MESSAGE_ROW_SEPARATOR);

    copyToClipboard(content).catch(() => {
      $q.notify({
        type: "negative",
        message: "Copy to clipboard failed",
      });
    });
  };

  const onExportToCsv = () =>
    downloadMessageDeliveryListAsCsv(
      productLine.value,
      filters.value,
      pagination.value
    );

  const createdByOptions = ref<string[]>([]);
  watch(
    [productLine],
    async () => {
      const users = await fetchMessageDeliveryUsers(productLine.value);
      createdByOptions.value = users.map((user) => user.username).sort();
    },
    { immediate: true }
  );

  const permissions = usePushMessagePermissions({
    productLineId: computed(() => productLine.value),
  });

  return {
    product,
    productLine,
    // Event handlers.
    onOpenExistingPushMessage,
    onOpenExistingPushMessageInNewTab,
    onAddNewPushMessage,
    onClipboardCopyMessageRow,
    onExportToCsv,
    // Data.
    messageDeliveries,
    isLoading,
    isError,
    // Pagination.
    pagination,
    onPaginationChange,
    // Filters.
    filters,
    onFiltersChange,
    // Options.
    statusOptions,
    createdByOptions,
    // Permissions.
    ...permissions,
  };
}
