
import { defineComponent, PropType } from "vue";

import TimeInput from "./TimeInput.vue";

export default defineComponent({
  components: {
    TimeInput,
  },
  props: {
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
    startTime: {
      type: null as unknown as PropType<string | null>,
      required: false,
      default: null,
    },
    endTime: {
      type: null as unknown as PropType<string | null>,
      required: false,
      default: null,
    },
    startTimeLabel: {
      type: String,
      required: false,
      default: "Start Time",
    },
    endTimeLabel: {
      type: String,
      required: false,
      default: "End Time",
    },
  },
  emits: {
    "update:startTime": (v: string | null) =>
      v === null || typeof v === "string",
    "update:endTime": (v: string | null) => v === null || typeof v === "string",
  },
});
