import camelcaseKeys from "camelcase-keys";

import { httpClient, QueryParams } from "@/lib/http";
import { cast } from "@/lib/typing";

import { MessageDeliveryStatusGuard } from ".";
import {
  MessageDelivery,
  MessageDeliveryGuard,
  MessageDeliveryStatus,
} from "./typedefs";

export function convertMessageDeliveryStatus(
  rawStatus: unknown
): MessageDeliveryStatus | null {
  try {
    return cast(MessageDeliveryStatusGuard, rawStatus);
  } catch (error) {
    return null;
  }
}

export function convertMessageDelivery(data: unknown): MessageDelivery {
  const messageDeliveryData = data as {
    id?: unknown;
    status?: unknown;
    delivery_time?: unknown;
    estimated_users?: unknown;
    messages_sent?: unknown;
    message?: unknown;
  };

  // TODO(PNS-1481): Make conversion fallback to default values.
  // TODO(PNS-1738): Drop when BE will start sending camelCase.
  const camelCasedData = camelcaseKeys(messageDeliveryData, { deep: true });
  return cast(MessageDeliveryGuard, camelCasedData);
}

export function convertMessageDeliveries(data: unknown): MessageDelivery[] {
  if (!Array.isArray(data)) return [];
  return data.map((messageDelivery) => convertMessageDelivery(messageDelivery));
}

export async function fetchMessageDeliveries(
  productLine: number,
  queryParams?: QueryParams,
  signal?: AbortSignal
): Promise<{ data: MessageDelivery[]; count: number }> {
  const response = await httpClient.get(
    `/api/pushnotifications/product-lines/${productLine}/message-deliveries/`,
    queryParams,
    { signal: signal }
  );
  const responseBody = await response.json();
  // NOTE: When there is pagination, backend returns paginated items at 'results' attribute.
  // Otherwise it returns array of all items.
  const data = responseBody["results"] ?? responseBody;
  const count = responseBody["count"] ?? responseBody?.length ?? 0;
  return { data: convertMessageDeliveries(data), count };
}
