
import QForm from 'quasar/src/components/form/QForm.js';;
import { defineComponent, PropType, Ref, ref, toRef } from "vue";

import { DateTimeRange, isValidRange } from "@/lib/dateTime";

import TimeRangeInput from "./TimeRangeInput.vue";
import { useDateTimeRangeInput } from "./useDateTimeRangeInput";
import { useInputProps } from "./useInputProps";

export default defineComponent({
  components: {
    TimeRangeInput,
  },
  props: {
    ...useInputProps(),
    modelValue: {
      required: true,
      type: null as unknown as PropType<DateTimeRange | null>,
      validator: (v: unknown) => v === null || isValidRange(v as DateTimeRange),
    },
  },
  emits: {
    "update:modelValue": (v: DateTimeRange | null) =>
      typeof v === "object" || v === null,
  },
  setup(props, { emit }) {
    const formRef: Ref<QForm | null> = ref(null);
    return {
      ...useDateTimeRangeInput({
        modelValue: toRef(props, "modelValue"),
        onRangeChange: (value) => emit("update:modelValue", value),
        extraRules: toRef(props, "extraRules"),
        formValidator: () => formRef.value?.validate(),
      }),
      formRef,
    };
  },
});
