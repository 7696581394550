import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';;
import { computed, ref, Ref, watch } from "vue";

import {
  dateTimeFromString,
  DateTimeRange,
  dateTimeRangeFromString,
  isRangeStringStartLessOrEqualEnd,
  isRangeStringValidFormat,
} from "@/lib/dateTime";
import { ValidationRule } from "@/lib/rules/typedefs";

interface DateTimeRangeInputOptions {
  modelValue: Ref<DateTimeRange | null | string>;
  onRangeChange: (arg: DateTimeRange | null) => void;
  extraRules?: Ref<ValidationRule<string | null>[]>;
  formValidator?: () => Promise<boolean> | undefined;
}

const DEFAULT_RULES: ValidationRule<string | null>[] = [
  (val: string | null) =>
    val === null ||
    val === "" ||
    isRangeStringValidFormat(val) ||
    "This is not a valid date time range",
  (val: string | null) =>
    val === null ||
    val === "" ||
    isRangeStringStartLessOrEqualEnd(val) ||
    "Start date cannot be later than end date.",
];

export function useDateTimeRangeInput({
  modelValue,
  onRangeChange,
  extraRules,
  formValidator,
}: DateTimeRangeInputOptions) {
  const startTime: Ref<string | null> = ref(null);
  const endTime: Ref<string | null> = ref(null);
  const dateRange: Ref<{ from: string; to: string } | null | string> =
    ref(null);
  const popupRef: Ref<QPopupProxy | null> = ref(null);
  const inputText: Ref<string | null> = ref(null);

  watch(
    [modelValue],
    () => {
      if (modelValue.value === null) {
        inputText.value = "";
        return;
      }

      let rawRange = modelValue.value;
      if (typeof rawRange === "string") {
        rawRange = {
          from: rawRange,
          to: rawRange,
        };
      }
      const initStartDateTime =
        rawRange !== null ? dateTimeFromString(rawRange.from) : null;
      const initEndDateTime =
        rawRange !== null ? dateTimeFromString(rawRange.to) : null;

      if (initStartDateTime === null) return;
      if (startTime.value !== initStartDateTime.time) {
        startTime.value = initStartDateTime.time;
      }

      if (initEndDateTime === null) return;
      if (endTime.value !== initEndDateTime.time) {
        endTime.value = initEndDateTime.time;
      }
      if (typeof dateRange.value === "string") {
        dateRange.value = {
          from: dateRange.value,
          to: dateRange.value,
        };
      }
      if (
        dateRange.value?.from !== initStartDateTime.date ||
        dateRange.value?.to !== initEndDateTime.date
      ) {
        dateRange.value = {
          from: initStartDateTime.date,
          to: initEndDateTime.date,
        };
      }

      const from = `${dateRange.value.from} ${startTime.value}`;
      const to = `${dateRange.value.to} ${endTime.value}`;
      if (inputText.value !== `${from} - ${to}`) {
        inputText.value = `${from} - ${to}`;
      }
    },
    { immediate: true }
  );

  const onInputTextChange = () => {
    if (!inputText.value) {
      onRangeChange(null);
      return;
    }

    const isValidFormat = isRangeStringValidFormat(inputText.value);
    const isStartLessThanEnd = isRangeStringStartLessOrEqualEnd(
      inputText.value
    );
    if (!isValidFormat || !isStartLessThanEnd) {
      return;
    }

    const range = dateTimeRangeFromString(inputText.value);
    if (range === null) return;

    onRangeChange(range);
  };

  const rules = computed(() => {
    return [...DEFAULT_RULES, ...(extraRules?.value ?? [])];
  });

  const dateRangeValidationRules: ValidationRule<string | null>[] = [
    (val: string | null) =>
      (val !== null && val !== "") || "Date range is required",
  ];

  const setDateTimeRange = () => {
    if (
      startTime.value !== null &&
      endTime.value !== null &&
      dateRange.value !== null
    ) {
      if (typeof dateRange.value === "string") {
        dateRange.value = {
          from: dateRange.value,
          to: dateRange.value,
        };
      }
      const from = `${dateRange.value.from} ${startTime.value}`;
      const to = `${dateRange.value.to} ${endTime.value}`;
      const rangeString = `${from} - ${to}`;
      inputText.value = rangeString;
      if (isRangeStringValidFormat(rangeString)) {
        onRangeChange({ from, to });
      }
    } else if (inputText.value !== null) {
      inputText.value = null;
      onRangeChange(null);
    }
  };

  const onFormSubmit = async () => {
    const validationResult = await formValidator?.();
    if (!validationResult) {
      return;
    }

    setDateTimeRange();
    popupRef.value?.hide();
  };

  return {
    rules,
    startTime,
    endTime,
    dateRange,
    inputText,
    popupRef,
    dateRangeValidationRules,
    onFormSubmit,
    onInputTextChange,
    isRangeStringValidFormat,
    isRangeStringStartLessOrEqualEnd,
  };
}

export const TEST_ONLY = { DEFAULT_RULES };
