import {
  dateTimeRangeFromString,
  isRangeStringValidFormat,
} from "@/lib/dateTime";
import { isValidRangeFilterFormat } from "@/lib/filters";
import { QueryParams } from "@/lib/http";
import { cast } from "@/lib/typing";

import { DeliveryTypeGuard } from "../shared/typedefs";
import { convertMessageDeliveryStatus } from "./list";
import { MessageDeliveryFilters } from "./typedefs";

const INITIAL_FILTERS: MessageDeliveryFilters = {
  name: null,
  status: null,
  messageId: null,
  deliveryDateTimeRange: null,
  createdBy: null,
  deliveryType: null,
  campaignId: null,
  tag: null,
};

export function buildMessageDeliveryQueryParamsFromFilters(
  filters: MessageDeliveryFilters
): Record<string, string> {
  const params: Record<string, string> = {};

  if (filters.name !== null) {
    params["message_name"] = filters.name;
  }

  if (filters.status !== null) {
    params["status"] = filters.status;
  }

  if (filters.messageId !== null) {
    params["message_id"] = filters.messageId;
  }

  if (filters.createdBy !== null) {
    params["message_created_by"] = filters.createdBy;
  }

  if (filters.deliveryDateTimeRange !== null) {
    const { from, to } = filters.deliveryDateTimeRange;
    const rangeString = `${from} - ${to}`;
    if (isRangeStringValidFormat(rangeString)) {
      params["delivery_time"] = rangeString;
    }
  }

  if (filters.deliveryType !== null) {
    params["message_delivery_type"] = filters.deliveryType;
  }

  if (filters.campaignId !== null) {
    params["message_campaign_id"] = filters.campaignId;
  }

  if (filters.tag !== null) {
    params["message_tag"] = String(filters.tag);
  }

  return params;
}

export function buildMessageDeliveryFiltersFromQueryParams(
  queryParams: QueryParams
): MessageDeliveryFilters {
  const filters = INITIAL_FILTERS;

  if (typeof queryParams["message_name"] === "string") {
    filters.name = queryParams["message_name"];
  }

  if (typeof queryParams["status"] === "string") {
    filters.status = convertMessageDeliveryStatus(queryParams["status"]);
  }

  if (typeof queryParams["message_id"] === "string") {
    const messageId = String(queryParams["message_id"]);
    if (isValidRangeFilterFormat(messageId)) {
      filters.messageId = queryParams["message_id"];
    }
  }

  if (typeof queryParams["message_created_by"] === "string") {
    const createdBy = queryParams["message_created_by"];
    filters.createdBy = createdBy;
  }

  if (typeof queryParams["delivery_time"] === "string") {
    const deliveryTimeQueryParam = queryParams["delivery_time"];
    const range = dateTimeRangeFromString(deliveryTimeQueryParam);
    if (range !== null) {
      filters.deliveryDateTimeRange = range;
    }
  }

  if (typeof queryParams["message_delivery_type"] === "string") {
    filters.deliveryType = cast(
      DeliveryTypeGuard,
      queryParams["message_delivery_type"]
    );
  }

  if (typeof queryParams["message_campaign_id"] === "string") {
    const campaignId = String(queryParams["message_campaign_id"]);
    if (isValidRangeFilterFormat(campaignId)) {
      filters.campaignId = queryParams["message_campaign_id"];
    }
  }

  if (typeof queryParams["message_tag"] === "string") {
    const tagId = parseInt(queryParams["message_tag"]);
    if (!isNaN(tagId)) {
      filters.tag = tagId;
    }
  }

  return filters;
}

export const TEST_ONLY = {
  INITIAL_FILTERS,
};
