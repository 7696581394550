import { Ref, ref, watch } from "vue";
import { useRouter } from "vue-router";

import {
  MessageDelivery,
  MessageDeliveryFilters,
} from "@/api/pushnotifications/messagedelivery";
import { fetchMessageDeliveries } from "@/api/pushnotifications/messagedelivery";
import { buildMessageDeliveryQueryParamsFromFilters } from "@/api/pushnotifications/messagedelivery/filters";
import { DataState } from "@/composables/typedefs";
import { buildQueryParamsFromPagination, Pagination } from "@/lib/pagination";

const UPDATE_TIMEOUT_SECONDS = 30;

interface MessageDeliveryListDataOptions {
  productLine: Ref<number>;
  initialFilters: MessageDeliveryFilters;
  initialPagination: Pagination;
}

export function useMessageDeliveryList(
  options: MessageDeliveryListDataOptions
) {
  const router = useRouter();
  const messageDeliveries = ref<MessageDelivery[]>([]);
  const pagination = ref<Pagination>(options.initialPagination);
  const filters = ref<MessageDeliveryFilters>(options.initialFilters);
  let updateController: AbortController | undefined;

  const onPaginationChange = async (newPagination: Pagination) => {
    pagination.value = newPagination;
    await tryToFetchMessageDeliveries();
  };

  const onFiltersChange = async (newFilters: MessageDeliveryFilters) => {
    filters.value = newFilters;
    await tryToFetchMessageDeliveries();
  };

  const state: Ref<DataState> = ref(DataState.INITIAL);

  const tryToFetchMessageDeliveries = async () => {
    if (updateController) {
      updateController.abort();
    }
    state.value = DataState.LOADING;
    try {
      const queryParams = {
        ...buildQueryParamsFromPagination(pagination.value),
        ...buildMessageDeliveryQueryParamsFromFilters(filters.value),
      };

      const { data, count } = await fetchMessageDeliveries(
        options.productLine.value,
        queryParams
      );

      messageDeliveries.value = data;
      pagination.value.rowsNumber = count;
      await router.push({ query: queryParams });
      state.value = DataState.LOADED;

      updateController = new AbortController();
      window.setTimeout(() => {
        updateController?.abort();
      }, UPDATE_TIMEOUT_SECONDS * 1000);
      fetchMessageDeliveries(
        options.productLine.value,
        {
          ...queryParams,
          fetch_updates: "true",
        },
        updateController.signal
      )
        .then(({ data, count }) => {
          messageDeliveries.value = data;
          pagination.value.rowsNumber = count;
          updateController = undefined;
        })
        .catch((error: unknown) => {
          if (error instanceof DOMException && error.name === "AbortError") {
            return;
          }
          throw error;
        });
    } catch (error: unknown) {
      state.value = DataState.ERROR;
      throw error;
    }
  };

  watch([options.productLine], () => tryToFetchMessageDeliveries());

  return {
    messageDeliveries,
    state,
    pagination,
    onPaginationChange,
    filters,
    onFiltersChange,
  };
}
