import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_row_context_menu = _resolveComponent("row-context-menu")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_list_content = _resolveComponent("list-content")!

  return (_openBlock(), _createBlock(_component_list_content, {
    "error-message": "Failed to fetch list of in messages",
    "is-error": _ctx.isError
  }, {
    header: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", {
        class: "text-h6 q-py-sm",
        "data-test": "pm.list.header"
      }, "Message list", -1)
    ])),
    "right-panel": _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_q_btn, {
          color: "primary",
          "icon-right": "archive",
          label: "Export to CSV",
          "no-caps": "",
          onClick: _ctx.handleExportToCsvClick
        }, null, 8, ["onClick"])
      ])
    ]),
    table: _withCtx(() => [
      _createVNode(_component_q_table, {
        pagination: _ctx.pagination,
        "rows-per-page-options": _ctx.DEFAULT_ROWS_PER_PAGE,
        columns: _ctx.columns,
        rows: _ctx.messageDeliveries,
        loading: _ctx.isLoading,
        "no-data-label": "Found no messages",
        flat: "",
        "binary-state-sort": "",
        "data-test": "pm.list.table",
        "onUpdate:pagination": _ctx.onPaginationChange,
        onRequest: _ctx.onRequest
      }, {
        body: _withCtx((props) => [
          _createVNode(_component_q_tr, {
            props: props,
            class: "cursor-pointer",
            "data-test": "pm.list.row",
            onClick: ($event: any) => (_ctx.handleRowClick($event, props.row)),
            onMouseup: _withModifiers(($event: any) => (_ctx.handleMiddleRowClick($event, props.row)), ["middle"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_row_context_menu, {
                onOpenClick: ($event: any) => (_ctx.handleRowClick($event, props.row)),
                onOpenNewTabClick: ($event: any) => (_ctx.handleMenuOpenNewTabClick($event, props.row)),
                onCopyToClipboardClick: ($event: any) => (
                _ctx.handleCopyToClipboardClick($event, props.row)
              )
              }, null, 8, ["onOpenClick", "onOpenNewTabClick", "onCopyToClipboardClick"]),
              _createVNode(_component_q_td, {
                key: "message_id",
                props: props,
                "data-test": "pm.list.cell.id"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.message.id), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "status",
                props: props
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_q_badge, {
                      color: _ctx.getStatusColor(props.row.status),
                      label: props.row.status
                    }, null, 8, ["color", "label"])
                  ])
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "message_name",
                props: props
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.message.name), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "message_delivery_type",
                props: props
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_q_badge, {
                      color: _ctx.getDeliveryTypeColor(props.row.message.deliveryType),
                      label: props.row.message.deliveryType
                    }, null, 8, ["color", "label"])
                  ])
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "delivery_time",
                props: props
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.deliveryTime), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "message_created_by",
                props: props
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.message.createdBy), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "sent",
                "data-test": "pm.list.cell.sent",
                props: props
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.messagesSent === null
                  ? ""
                  : _ctx.formatPretty(props.row.messagesSent)), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "affected",
                "data-test": "pm.list.cell.affected",
                props: props
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.estimatedUsers === null
                  ? ""
                  : _ctx.formatPretty(props.row.estimatedUsers)), 1)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            _: 2
          }, 1032, ["props", "onClick", "onMouseup"])
        ]),
        _: 1
      }, 8, ["pagination", "rows-per-page-options", "columns", "rows", "loading", "onUpdate:pagination", "onRequest"])
    ]),
    _: 1
  }, 8, ["is-error"]))
}