
import { defineComponent } from "vue";

import ListHeader from "@/components/shared/list/ListHeader.vue";

export default defineComponent({
  components: {
    ListHeader,
  },
  props: {
    addButtonVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ["addNewPushMessage"],
});
