import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "push-messages q-pa-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_push_messages_header = _resolveComponent("push-messages-header")!
  const _component_push_messages_filters = _resolveComponent("push-messages-filters")!
  const _component_push_messages_list = _resolveComponent("push-messages-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_push_messages_header, {
      "add-button-visible": _ctx.hasAddPushMessagePermission,
      onAddNewPushMessage: _ctx.onAddNewPushMessage
    }, null, 8, ["add-button-visible", "onAddNewPushMessage"]),
    _createVNode(_component_push_messages_filters, {
      "status-options": _ctx.statusOptions,
      "created-by-options": _ctx.createdByOptions,
      filters: _ctx.filters,
      onFiltersChange: _ctx.onFiltersChange
    }, null, 8, ["status-options", "created-by-options", "filters", "onFiltersChange"]),
    _createVNode(_component_push_messages_list, {
      "message-deliveries": _ctx.messageDeliveries,
      pagination: _ctx.pagination,
      "is-loading": _ctx.isLoading,
      "is-error": _ctx.isError,
      onPaginationChange: _ctx.onPaginationChange,
      onRowClick: _ctx.onOpenExistingPushMessage,
      onRowMiddleClick: _ctx.onOpenExistingPushMessageInNewTab,
      onRowMenuOpenClick: _ctx.onOpenExistingPushMessage,
      onRowMenuOpenNewTabClick: _ctx.onOpenExistingPushMessageInNewTab,
      onRowMenuClipboardCopyClick: _ctx.onClipboardCopyMessageRow,
      onExportToCsvClick: _ctx.onExportToCsv
    }, null, 8, ["message-deliveries", "pagination", "is-loading", "is-error", "onPaginationChange", "onRowClick", "onRowMiddleClick", "onRowMenuOpenClick", "onRowMenuOpenNewTabClick", "onRowMenuClipboardCopyClick", "onExportToCsvClick"])
  ]))
}