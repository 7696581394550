import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row items-center justify-center" }
const _hoisted_2 = { class: "row items-center justify-end q-mt-sm q-pb-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_field = _resolveComponent("q-field")!
  const _component_time_range_input = _resolveComponent("time-range-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_input, {
    modelValue: _ctx.inputText,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.inputText) = $event)),
    clearable: "",
    filled: "",
    dense: _ctx.dense,
    label: _ctx.label,
    hint: _ctx.hint,
    disable: _ctx.disable,
    mask: "####-##-## ##:## - ####-##-## ##:##",
    "data-test": "datetimerangeinput.input",
    rules: _ctx.rules,
    onChange: _ctx.onInputTextChange,
    onClear: _ctx.onInputTextChange
  }, {
    append: _withCtx(() => [
      _createVNode(_component_q_icon, {
        name: "event",
        class: "cursor-pointer"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_popup_proxy, {
            ref: "popupRef",
            "transition-show": "scale",
            "transition-hide": "scale"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_card, { style: {"max-width":"min-content"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_form, {
                    ref: "formRef",
                    greedy: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_field, {
                        modelValue: _ctx.dateRange,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dateRange) = $event)),
                        rules: _ctx.dateRangeValidationRules,
                        "no-error-icon": ""
                      }, {
                        control: _withCtx(() => [
                          _createVNode(_component_q_date, {
                            modelValue: _ctx.dateRange,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateRange) = $event)),
                            range: "",
                            minimal: "",
                            flat: "",
                            mask: "YYYY-MM-DD"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }, 8, ["modelValue", "rules"]),
                      _createElementVNode("div", _hoisted_1, [
                        _createVNode(_component_time_range_input, {
                          "start-time": _ctx.startTime,
                          "onUpdate:startTime": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.startTime) = $event)),
                          "end-time": _ctx.endTime,
                          "onUpdate:endTime": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.endTime) = $event))
                        }, null, 8, ["start-time", "end-time"])
                      ]),
                      _createElementVNode("div", _hoisted_2, [
                        _withDirectives(_createVNode(_component_q_btn, {
                          label: "Cancel",
                          color: "primary",
                          flat: ""
                        }, null, 512), [
                          [_directive_close_popup]
                        ]),
                        _createVNode(_component_q_btn, {
                          label: "Save",
                          color: "primary",
                          flat: "",
                          onClick: _ctx.onFormSubmit
                        }, null, 8, ["onClick"])
                      ])
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_q_tooltip, null, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode("Click on calendar icon to set")
        ])),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "dense", "label", "hint", "disable", "rules", "onChange", "onClear"]))
}