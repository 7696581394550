
import debounce from 'quasar/src/utils/debounce.js';import QForm from 'quasar/src/components/form/QForm.js';;
import { computed, defineComponent, PropType, Ref, ref, watch } from "vue";

import {
  MessageDeliveryFilters,
  MessageDeliveryStatus,
} from "@/api/pushnotifications/messagedelivery";
import {
  DeliveryType,
  DeliveryTypeGuard,
} from "@/api/pushnotifications/messagedelivery/typedefs";
import { getUpdateSingleTagListener } from "@/api/tags/helpers";
import { fetchTags } from "@/api/tags/list";
import DateTimeRangeInput from "@/components/shared/DateTimeRangeInput.vue";
import ListFilters from "@/components/shared/list/ListFilters.vue";
import TagSelect from "@/components/shared/tags/TagSelect.vue";
import { DateTimeRange } from "@/lib/dateTime";
import { isValidRangeFilterFormat } from "@/lib/filters";

export default defineComponent({
  components: {
    TagSelect,
    DateTimeRangeInput,
    ListFilters,
  },
  props: {
    filters: {
      type: Object as PropType<MessageDeliveryFilters>,
      required: true,
    },
    createdByOptions: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    statusOptions: {
      type: Array as PropType<MessageDeliveryStatus[]>,
      default: () => [],
    },
  },
  emits: {
    filtersChange: (payload: MessageDeliveryFilters) =>
      typeof payload === "object",
  },
  setup(props, { emit }) {
    const filtersForm: Ref<QForm | null> = ref(null);
    const name: Ref<string | null> = ref(props.filters.name);
    const status: Ref<MessageDeliveryStatus | null> = ref(props.filters.status);
    const messageId: Ref<string | null> = ref(props.filters.messageId);
    const createdBy: Ref<string | null> = ref(props.filters.createdBy);
    const deliveryType: Ref<DeliveryType | null> = ref(
      props.filters.deliveryType
    );
    const deliveryDateTimeRange: Ref<DateTimeRange | null> = ref(
      props.filters.deliveryDateTimeRange
    );
    const campaignId: Ref<string | null> = ref(props.filters.campaignId);
    const tagId: Ref<number | null> = ref(props.filters.tag);

    const updateTagId = getUpdateSingleTagListener(tagId);

    const emitFiltersChange = debounce(async () => {
      const isValid = await filtersForm.value?.validate();
      if (!isValid) return;

      emit("filtersChange", {
        name: name.value,
        status: status.value,
        messageId: messageId.value,
        deliveryDateTimeRange: deliveryDateTimeRange.value,
        createdBy: createdBy.value,
        deliveryType: deliveryType.value,
        campaignId: campaignId.value,
        tag: tagId.value,
      });
    }, 500);

    const deliveryTypeOptions = ref(Object.keys(DeliveryTypeGuard.keys));

    const clearAllFilters = () => {
      name.value = null;
      status.value = null;
      messageId.value = null;
      deliveryDateTimeRange.value = null;
      createdBy.value = null;
      deliveryType.value = null;
      campaignId.value = null;
      tagId.value = null;
    };

    const isClearButtonVisible = computed(() => {
      return (
        Object.values(props.filters).find(
          (val) => val !== null && val !== ""
        ) !== undefined
      );
    });

    watch(
      [
        name,
        status,
        messageId,
        createdBy,
        deliveryDateTimeRange,
        deliveryType,
        campaignId,
        tagId,
      ],
      () => emitFiltersChange()
    );

    return {
      name,
      status,
      messageId,
      deliveryDateTimeRange,
      createdBy,
      deliveryType,
      deliveryTypeOptions,
      campaignId,
      tagId,
      filtersForm,
      clearAllFilters,
      isClearButtonVisible,
      isValidRangeFilterFormat,
      fetchTags,
      updateTagId,
    };
  },
});
