// TODO(PNS-2863): Remove this file in favor of pushmessage/typedefs.ts
import * as t from "io-ts";

export enum DeliveryType {
  IMMEDIATE = "Immediate",
  SCHEDULED = "Scheduled",
  RECURRING = "Recurring",
}

export const DeliveryTypeGuard = t.keyof({
  [DeliveryType.IMMEDIATE]: null,
  [DeliveryType.SCHEDULED]: null,
  [DeliveryType.RECURRING]: null,
});
