import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row q-col-gutter-md q-gutter-y-sm" }
const _hoisted_2 = { class: "col-12 col-sm-3 q-gutter-y-md" }
const _hoisted_3 = { class: "col-12 col-sm-3 q-gutter-y-md" }
const _hoisted_4 = { class: "col-12 col-sm-3 q-gutter-y-md" }
const _hoisted_5 = { class: "col-12 col-sm-3 q-gutter-y-md" }
const _hoisted_6 = { "data-test": "pm.filters.tag-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_date_time_range_input = _resolveComponent("date-time-range-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_tag_select = _resolveComponent("tag-select")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_list_filters = _resolveComponent("list-filters")!

  return (_openBlock(), _createBlock(_component_list_filters, { title: "Filter messages" }, {
    "title-bar": _withCtx(() => [
      (_ctx.isClearButtonVisible)
        ? (_openBlock(), _createBlock(_component_q_btn, {
            key: 0,
            outline: "",
            rounded: "",
            color: "primary",
            "icon-right": "clear",
            label: "Clear all filters",
            "data-test": "pm.filters.clear",
            "no-caps": "",
            onClick: _ctx.clearAllFilters
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_q_form, { ref: "filtersForm" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_q_input, {
                modelValue: _ctx.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
                dense: "",
                filled: "",
                label: "Name",
                "data-test": "pm.filters.name"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_date_time_range_input, {
                modelValue: _ctx.deliveryDateTimeRange,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.deliveryDateTimeRange) = $event)),
                "data-test": "pm.filters.deliverytime",
                label: "Delivery time",
                dense: ""
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_q_select, {
                modelValue: _ctx.status,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.status) = $event)),
                dense: "",
                clearable: "",
                filled: "",
                options: _ctx.statusOptions,
                label: "Status",
                "data-test": "pm.filters.status"
              }, null, 8, ["modelValue", "options"]),
              _createVNode(_component_q_select, {
                modelValue: _ctx.createdBy,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.createdBy) = $event)),
                dense: "",
                clearable: "",
                filled: "",
                options: _ctx.createdByOptions,
                "data-test": "pm.filters.createdby",
                label: "Created by"
              }, null, 8, ["modelValue", "options"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_q_input, {
                modelValue: _ctx.messageId,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.messageId) = $event)),
                class: "q-pb-none",
                dense: "",
                filled: "",
                label: "ID",
                placeholder: "i.e. 1,2,5-10",
                "data-test": "pm.filters.id",
                rules: [
              (val) =>
                val === null ||
                val === '' ||
                _ctx.isValidRangeFilterFormat(val) ||
                'Filter format invalid, must be comma separated list of ids, i.e. 1,2,5-10',
            ]
              }, null, 8, ["modelValue", "rules"]),
              _createVNode(_component_q_select, {
                modelValue: _ctx.deliveryType,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.deliveryType) = $event)),
                dense: "",
                clearable: "",
                filled: "",
                options: _ctx.deliveryTypeOptions,
                "data-test": "pm.filters.deliverytype",
                label: "Delivery type"
              }, null, 8, ["modelValue", "options"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_q_input, {
                modelValue: _ctx.campaignId,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.campaignId) = $event)),
                class: "q-pb-none",
                dense: "",
                filled: "",
                label: "Campaign ID",
                placeholder: "i.e. 1,2,5-10",
                "data-test": "pm.filters.campaignid",
                rules: [
              (val) =>
                val === null ||
                val === '' ||
                _ctx.isValidRangeFilterFormat(val) ||
                'Filter format invalid, must be comma separated list of campaign ids, i.e. 1,2,5-10',
            ]
              }, null, 8, ["modelValue", "rules"]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_tag_select, {
                  "data-test": "pm.filters.tag",
                  label: "Tag",
                  "fetch-options": _ctx.fetchTags,
                  "selected-tag-id": _ctx.tagId,
                  "single-select-mode": "",
                  "onUpdate:modelValue": _ctx.updateTagId
                }, null, 8, ["fetch-options", "selected-tag-id", "onUpdate:modelValue"])
              ])
            ])
          ])
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }))
}