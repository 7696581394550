import { httpClient, throwIfBadResponse } from "@/lib/http";
import { cast } from "@/lib/typing";

import { User, UserGuard } from "../../users";

function convertUsers(data: unknown): User[] {
  if (!Array.isArray(data)) return [];
  return data.map((user) => cast(UserGuard, user));
}

export async function fetchMessageDeliveryUsers(
  productLine: number
): Promise<User[]> {
  const response = await httpClient.get(
    `/api/pushnotifications/product-lines/${productLine}/message-delivery-users/`
  );

  await throwIfBadResponse(response);

  const data = await response.json();

  return convertUsers(data);
}
