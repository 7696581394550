
import type { QTableProps } from "quasar";
import { defineComponent, PropType } from "vue";

import { MessageDelivery } from "@/api/pushnotifications/messagedelivery";
import { DeliveryType } from "@/api/pushnotifications/messagedelivery/typedefs";
import ListContent from "@/components/shared/list/ListContent.vue";
import RowContextMenu from "@/components/shared/rowcontextmenu/RowContextMenu.vue";
import { formatPretty } from "@/lib/format";
import { DEFAULT_ROWS_PER_PAGE, Pagination } from "@/lib/pagination";
import { getStatusColor } from "@/lib/status";

const columns: QTableProps["columns"] = [
  {
    name: "message_id",
    label: "ID",
    align: "left",
    sortable: true,
    field: (row: MessageDelivery) => row.id,
  },
  {
    name: "status",
    label: "Status",
    align: "left",
    sortable: true,
    field: (row: MessageDelivery) => row.status,
  },
  {
    name: "message_name",
    label: "Name",
    align: "left",
    sortable: true,
    field: (row: MessageDelivery) => row.message.name,
  },
  {
    name: "message_delivery_type",
    label: "Delivery Type",
    align: "left",
    sortable: true,
    field: (row: MessageDelivery) => row.message.deliveryType,
  },
  {
    name: "delivery_time",
    label: "Delivery Time",
    align: "left",
    sortable: true,
    field: (row: MessageDelivery) => row.deliveryTime,
  },
  {
    name: "message_created_by",
    label: "Created by",
    align: "left",
    sortable: true,
    field: (row: MessageDelivery) => row.message.createdBy,
  },
  {
    name: "sent",
    label: "Sent",
    align: "right",
    sortable: true,
    field: (row: MessageDelivery) => row.messagesSent,
  },
  {
    name: "affected",
    label: "Affected",
    align: "right",
    sortable: true,
    field: (row: MessageDelivery) => row.estimatedUsers,
  },
];

function getDeliveryTypeColor(deliveryType: DeliveryType) {
  switch (deliveryType) {
    case "Immediate":
      return "blue";
    case "Scheduled":
      return "green";
    case "Triggered":
      return "purple";
    default:
      return "orange";
  }
}

export default defineComponent({
  components: {
    RowContextMenu,
    ListContent,
  },
  props: {
    messageDeliveries: {
      type: Object as PropType<MessageDelivery[]>,
      required: true,
    },
    pagination: {
      type: Object as PropType<Pagination>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isError: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: {
    paginationChange: (pagination: Pagination) =>
      typeof pagination === "object",
    rowClick: (id: number) => typeof id === "number",
    rowMiddleClick: (id: number) => typeof id === "number",
    rowMenuOpenClick: (id: number) => typeof id === "number",
    rowMenuOpenNewTabClick: (id: number) => typeof id === "number",
    rowMenuClipboardCopyClick: (delivery: MessageDelivery) =>
      typeof delivery === "object",
    exportToCsvClick: () => true,
  },
  setup(props, { emit }) {
    const onRequest = (payload: { pagination: Pagination }) => {
      emit("paginationChange", payload.pagination);
    };

    const onPaginationChange = (pagination: Pagination) => {
      emit("paginationChange", pagination);
    };

    const handleRowClick = (event: MouseEvent, row: MessageDelivery) =>
      emit("rowClick", row.message.id);

    const handleMiddleRowClick = (event: MouseEvent, row: MessageDelivery) =>
      emit("rowMiddleClick", row.message.id);

    const handleMenuOpenClick = (event: PointerEvent, row: MessageDelivery) =>
      emit("rowMenuOpenClick", row.message.id);

    const handleMenuOpenNewTabClick = (
      event: PointerEvent,
      row: MessageDelivery
    ) => emit("rowMenuOpenNewTabClick", row.message.id);

    const handleCopyToClipboardClick = (
      event: PointerEvent,
      row: MessageDelivery
    ) => emit("rowMenuClipboardCopyClick", row);

    const handleExportToCsvClick = () => emit("exportToCsvClick");

    return {
      DEFAULT_ROWS_PER_PAGE,
      columns,
      onRequest,
      onPaginationChange,
      getStatusColor,
      getDeliveryTypeColor,
      // row click handlers
      handleRowClick,
      handleMiddleRowClick,
      // context menu handlers
      handleMenuOpenClick,
      handleMenuOpenNewTabClick,
      handleCopyToClipboardClick,
      // table handlers
      handleExportToCsvClick,
      // helpers
      formatPretty,
    };
  },
});
