
import { defineComponent, PropType, toRefs } from "vue";
import { LocationQuery, onBeforeRouteUpdate } from "vue-router";

import PushMessagesFilters from "@/components/pushmessages/PushMessagesFilters.vue";
import PushMessagesHeader from "@/components/pushmessages/PushMessagesHeader.vue";
import PushMessagesList from "@/components/pushmessages/PushMessagesList.vue";
import { mapParamsRouteGuard } from "@/router/mapParamsRouteGuard";

import { usePushMessages } from "./usePushMessages";

export default defineComponent({
  components: {
    PushMessagesHeader,
    PushMessagesFilters,
    PushMessagesList,
  },
  props: {
    product: {
      type: Number,
      required: true,
    },
    productLine: {
      type: Number,
      required: true,
    },
    queryParams: {
      type: Object as PropType<LocationQuery>,
      required: true,
    },
  },
  setup(props) {
    onBeforeRouteUpdate(mapParamsRouteGuard);
    return usePushMessages(toRefs(props));
  },
});
